<template>
  <div class="container-loading-spin">
    <div 
      v-for="n in 16" 
      :key="n" 
      class="circle" 
      :data-index="n">
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpin'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$COUNT: 16;
$SIZE: 20vh;
$STEP: ($SIZE / $COUNT);
$SPEED: 12s;
$COLOR: #fff;

* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container-loading-spin {
  position: relative;
  display: block;
  width: $SIZE;
  height: $SIZE;
  transform-style: preserve-3d;
  transform: perspective(1000px) rotateY(45deg) rotateX(45deg);
}

@for $n from 1 through $COUNT {
  $localSize: $SIZE - ($STEP * $n);
  $offset: ($STEP * $n) / 2;

  .circle:nth-child(#{$n}) {
    position: absolute;
    background: transparent;
    border: 2px solid $COLOR;
    border-radius: 50%;
    left: $offset;
    top: $offset;
    width: $localSize;
    height: $localSize;
    animation: spin ($SPEED / $n) infinite linear;
  }
}

.circle:nth-child(2n) {
  border: 2px dashed rgba(255, 255, 255, 0.5);
}

.circle:last-child {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

</style>
